$(function () {
    $('.confirm-item').click(function (e) {
        e.preventDefault();
        Swal.fire({
            title: 'This action is ireversible. Are you sure?',
            showCancelButton: true,
            confirmButtonText: 'Confirm Delete',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        }).then((result) => {
            if (result.value) {
                this.submit()
            } else {
                enableSubmitButtons($(this));
            }
        });
    })
})
